// DashboardPage.js
import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Banner from '../components/Banner';
import ProfileSection from '../components/ProfileSection'
import ExpressionEcriteSection from '../components/ExpressionEcriteSection';
import ComprenensionEcriteSection from '../components/ComprehensionEcriteSection'


const DashboardPage = () => {

  // State to keep track of active section
  const [activeSection, setActiveSection] = useState('expressionecritesection');

  // Function to render the section content based on `activeSection`
  const renderSectionContent = () => {
    switch (activeSection) {
      case 'profilesection':
        return <ProfileSection />;
      case 'expressionecritesection':
        return <ExpressionEcriteSection />;
      case 'comprehensionecritesection':
        return <ComprenensionEcriteSection />;

      // Add more cases as needed
      default:
        return <ExpressionEcriteSection />; // Default section
    }
  };

  return (
    <div className="w-full flex min-h-screen  px-[10px] py-10 xl:px-[67px]  justify-center ">
      <div className='flex w-full py-[30px] px-[38px] gap-[22px] rounded-[20px] bg-tcfbackground  '>
        <Sidebar setActiveSection={setActiveSection} />
        <div className="w-[70%] flex  flex-col gap-[16px] ">
          <Header />
          <Banner />
          <div className="section-content">
            {renderSectionContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;
