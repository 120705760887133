import React from 'react';
import ProfileCard from './ProfileCard';
import PointsProgress from './PointsProgress';
import Statistics from './Statistics';
import SubscriptionTable from './SubscriptionTable';
import ProgressionGeneral from './ProgressionGeneral';

const ProfileSection = () => {
    return (

        <div className="w-full">


            <div className='flex  w-full gap-2'>
                <div className='w-full grid grid-cols-1 gap-4'>
                    <ProfileCard />
                    <Statistics />
                </div>
                <div className='w-[40%] grid grid-cols-1 gap-4'>
                    <PointsProgress />
                    <ProgressionGeneral />
                </div>
            </div>
            <SubscriptionTable />


        </div>


    );
};

export default ProfileSection