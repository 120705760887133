// SeriesButtons.js
import React from 'react';

const TestButtons = () => (
  <div className="grid grid-cols-3 gap-7 ">
    {[...Array(3)].map((_, i) => (
      <button key={i} className="py-4 border border-white rounded-lg bg-white hover:bg-tcfbackground text-lg focus:bg-tcfbackground ">
       Tache {i + 1}
      </button>
    ))}
  </div>
);

export default TestButtons;
