import React, { useState } from 'react';
import SeriesButtons from './SeriesButtons';
import notificationbell from "../images/notification-white.svg"


const TabView = ({tests}) => {
    const [activeTab, setActiveTab] = useState(0);

    const tabs = ['Expression Ecrite', 'Correction Expression'];

    // Function to count words
    const countWords = (text) => {
        const words = text.trim().split(/\s+/);
        return words.filter(Boolean).length;
    };

    return (
        <div className="w-full">
            <div className="flex items-center  justify-between">
                {tabs.map((tab, index) => (
                    <div
                        key={index}
                        onClick={() => setActiveTab(index)}
                        className={` whitespace-nowrap text-center cursor-pointer pt-[8px] px-6 pb-2 rounded-tl-[40px] rounded-tr-[90px] transition-all duration-300 
              ${activeTab === index ? ' bg-white  w-[30%]' : ''}`}
                    >
                        {tab}
                    </div>

                ))}

                <div className="h-[20px] flex flex-row items-end  ">
                    <div className="flex items-center justify-center  h-full border-none rounded-l-[5px] bg-tcfred">
                        <img src={notificationbell} alt="" className="w-[32px] h-[32px]" />
                    </div>

                    <p className="flex items-center  px-2  justify-center whitespace-nowrap h-full rounded-r-[5px] text-tcfred text-[12px]  bg-[#dddde7d1]">
                        il vous rester, 07 jours, renouveler votre abonnement avant la fin
                    </p>
                </div>

            </div>

            <div className="px-4 pb-4 pt-3 bg-white  min-h-[120px] rounded-b-[20px]  rounded-tr-[20px] shadow-md">
                <div className={`tab-content ${activeTab === 0 ? 'block' : 'hidden'}`}>
                    <SeriesButtons tests={tests}/>
                </div>
                <div className={`tab-content ${activeTab === 1 ? 'block' : 'hidden'}`}>
                    <SeriesButtons tests={tests} />
                </div>
            </div>
        </div>
    );
};

export default TabView;
