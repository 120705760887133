import React from 'react';
import profileimage from '../images/profileimage.jpg'
import mailIcon from '../images/mail.svg'
import phoneIcon from '../images/phone.svg'
import editIcon from '../images/edit.svg'



const ProfileCard = () => (
  <div className="flex bg-white rounded-[15px] shadow w-full  p-2  gap-8 ">
    <div className='w-[148px] h-[148px]'>
      <img className="w-full h-full object-cover rounded-[15px]" src={profileimage} alt="User" />
    </div>

    <div className='flex flex-col pl-4'>
      <h3 className=" text-3xl font-bold ">Mariame Bah</h3>
      <p className="text-gray-500">@bahmariame</p>
      <div className="flex flex-col gap-1  mt-4">
        <div className='flex gap-1 text-xs items-center'>
          <img className="w-3 h-3" src={mailIcon} alt="" />
          <div >
            <p className=''>Email</p>
            <p className='text-gray-500'>bahmariame@gmail.com</p>
          </div>
          <img className="w-3 h-3 self-end" src={editIcon} alt="" />
        </div>
        <div className='flex gap-1 text-xs items-center'>
          <img className="w-3 h-3" src={phoneIcon} alt="" />
          <div >
            <p className=''>Téléphone</p>
            <p className='text-gray-500'>+1 456 756 6000</p>
          </div>
          <img className="w-3 h-3 self-end" src={editIcon} alt="" />
        </div>
      </div>
    </div>
  </div>
);

export default ProfileCard;
