import React from 'react';
import progressIcon from '../images/progress.svg'
import HalfDonutProgress from './DonutProgress';

const ProgressionGeneral = () => {
  const userprogress = 65;
  return (
    <div className=" flex flex-col  bg-white px-3 py-3 rounded-lg shadow justify-between ">
      <img className="w-5 h-5 bg-tcfbackground rounded-[5px]" src={progressIcon} alt="" />
      <div className="flex flex-col items-center  ">

        <HalfDonutProgress percentage={userprogress} />
      </div>




      <h3 className="flex items-center justify-center p-1 text-xs bg-gray-800 text-white rounded-[5px] font-semibold">PROGRESSION GÉNÉRALE</h3>


    </div>
  );
};

export default ProgressionGeneral;