// ResponseSection.js
import React, { useState } from 'react';
import ProgressBar from './ProgressBar';
import erroricon from '../images/exclamationicon.svg'




const ResponseSection = () => {
  const [text, setText] = useState('');
  const maxWords = 120;

  // Function to count words
  const countWords = (text) => {
    // Trim spaces and split by spaces, filter out empty entries (e.g., multiple spaces)
    const words = text.trim().split(/\s+/);
    return words.filter(Boolean).length;
  };

  const handleChange = (event) => {
    setText(event.target.value);
  };

  // Get the word count
  const wordCount = countWords(text);
  return (
    <div className="bg-white py-4 px-4 rounded-[20px] shadow  flex flex-col gap-3 ">
      <div className="text-white  w-full p-2 bg-tcfred rounded-md space-y-2">

        <div className='flex flex-row justify-between'>
          <p className='text-lg font-bold'> Consigne</p>

          <div className="w-[30px] h-[30px] flex items-center justify-center px-2 border-none rounded-full bg-white">
            <img src={erroricon} alt="" className="w-[24px] h-[24px]" />
          </div>
        </div>

        <p className=''> Rédaction d'un message pour decrire, raconte et/ou expliquer, adresser à un ou plusieurs destinataires, dont le status a été précisé dans la consigne.Minimum 60 mots - Maximum 120 mots</p>
      </div>

      <div className="flex flex-col items-start  bg-gray-100 p-5 rounded-[15px] gap-2">
        <div className='flex flex-row w-full justify-between'>
          <p className="text-lg font-normal">SUJET</p>
          <p className="text-lg font-bold  text-white rounded-md border-none bg-black px-3 ">Tache 1</p>
        </div>
        <p className="text-[20px] leading-[30.86px] font-extrabold text-gray-800 ">
          Vous passez un week-end à la campagne Ecrivez un message à votre ami(e) pour lui decrire ce qui s’est passé
        </p>
        <p className="text-sm text-tcfred mt-1">Minimum 60 mots - Maximum 120 mots</p>
      </div>


      <div className="relative w-full ">
        <textarea
          placeholder="Ecrire ici votre reponse ..."
          className="w-full p-4 border rounded-[15px] resize-none h-[250px] pr-10"
          rows="4"
          value={text}
          onChange={handleChange}
        ></textarea>
        <p className="absolute bottom-4 right-4 text-xs ">{wordCount}/{maxWords} mots</p>
      </div>
      <button className=" bg-tcfgreen text-white  py-4 rounded-md items-center justify-center mb-4">VALIDER VOTRE REPONSE</button>
      <ProgressBar totalTime={3600} />

    </div>
  );
}

export default ResponseSection;
