import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import QueryString from 'query-string';
import { useSelector } from 'react-redux';
import apiClient from '../services/httpService';

const PaymentConfirmationPage = () => {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const [mes, setMsg] = useState('');
    const location = useLocation();
    var button_text = "";

    if (isAuthenticated) {
        button_text = 'Go to dashboard';
    } else if (mes === "") {
        button_text = 'Loading....';
    } else {
        button_text = 'Go to login';
    }

    const handleRedirect = () => {
        if (isAuthenticated) {
            navigate('/dashboard');
        } else {
            navigate('/login');
        }
    };

    useEffect(() => {
        const values = QueryString.parse(location.search);

        // Ensure this runs only when `success` or `canceled` is present in the query
        if (values.success || values.canceled) {

            var payment_status = "";
            if (values.success === 'true') {
                payment_status = 'success';
            } else {
                payment_status = 'failed';
            }

            const update_server_payment = () => {
                apiClient.post('/payment/stripe/update-payment/', {
                    checkout_id: values.session_id,
                    payment_status: payment_status,
                    payment_id: values.payment_id
                })
                    .then(response => {
                        if (response.status === 200) {
                            console.log('Payment Verified', response.data?.message);
                            payment_status = "success";
                            setMsg('Payment Successful!');
                        } else {
                            payment_status = "failed";
                            setMsg('Payment Failed!');
                        }
                    });
            };

            // // Update the server only once
            if (values.payment_type !== "paystack") {
                update_server_payment();
            }else{
                if (values.success === 'true') {
                    setMsg('Payment Successful!');
                }
            }

        }
    }, [location.search]);

    return (
        <div className="">
            {mes}
            <button onClick={handleRedirect}>{button_text}</button>
        </div>
    );
};

export default PaymentConfirmationPage;
