// LoginSidebar.js
import React from 'react';
import imagebackground from '../images/background.png'

const SidePanel = ({ logo, bannerimage1, bannerimage2 }) => {
    return (
        <div className=" text-white w-1/2 px-8 bg-cover flex flex-col items-center justify-between" style={{ backgroundImage: `url(${imagebackground})` }}>

            <div className="pt-[20px] ">
                <img src={logo} alt="logo" className="w-full h-auto mb-2 " />
                <h2 className="text-2xl text-center font-semibold mb-4">Préparez votre TCF</h2>

            </div>
            <div className="relative">
                {bannerimage1 && (
                    <img
                        src={bannerimage1}
                        alt=""
                        className={`rounded-lg object-cover ${bannerimage2 ? 'absolute top-0 right-[50px] z-10' : ''}`}
                        style={{ maxWidth: '100%', height: 'auto' }} // Retain original size
                    />
                )}
                {bannerimage2 && (
                    <img
                        src={bannerimage2}
                        alt=""
                        className="rounded-lg object-cover"
                        style={{ zIndex: bannerimage1 ? '-1' : '0', maxWidth: '100%', height: 'auto' }} // Retain original size
                    />
                )}
            </div>




        </div>
    );
};

export default SidePanel;
