import React, { useEffect } from 'react';
import TabView from './TabView';
import TaskNumbers from './TaskNumbers';
import ResponseSection from './ComprehensionEcriteTestSection';
import apiClient from '../services/httpService';
import { useSelector } from 'react-redux';
import ProgressBar from './ProgressBar';

const ComprenensionEcriteSection = () => {

    const [tests, setTests] = React.useState([]);
    const { questions, currentIndex } = useSelector((state) => state.questions);


    const getTestsForCategory = async () => {
        const response = await apiClient.get('/mgmt/tests/001/');
        if (response.status === 200) {
            setTests(response.data);
        }
    }

    useEffect(() => {
        getTestsForCategory();
    }, []);

    console.log(questions);
    

    return (

        <div className="flex flex-col gap-4">
            <TabView tests={tests} />
            <TaskNumbers questions={questions?.normal_questions} />
            <ResponseSection question={questions?.normal_questions[currentIndex]} />
            <button className=" bg-[#3aba03] text-white  py-4 rounded-md  items-center justify-center mb-4">QUESTION SUIVANTE</button>
            <ProgressBar totalTime={questions?.duration} />
        </div>

    );
};

export default ComprenensionEcriteSection

