// src/components/SignUpForm.js
import React, { useState } from 'react';
import InputField from '../components/InputField';
import CountrySelect from '../components/CountrySelect';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSignUpCountry, setSignUpEmail, setSignUpFirstName, setSignUpLastName, setSignUpPassword, setSignUpPhone } from '../store/authSlice';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';



const SignUpForm = () =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [pasword, setPasword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    // Function to handle the "suivant" button click
    const handleContinue = () => {
      // Add any form validation or data handling here
      if (pasword !== confirmPassword) {
        alert('Passwords do not match');
        return;
      }
      navigate('/payment'); // Navigate to the payment page
    };

  const handleChange = (name, value) => {
    if (name === 'email') {
      dispatch(setSignUpEmail(value));
    } else if (name === 'password') {
      setPasword(value);
      dispatch(setSignUpPassword(value));
    } else if (name === 'confirm_password') {
      setConfirmPassword(value);
    } else if (name === 'firstName') {
      dispatch(setSignUpFirstName(value));
    } else if (name === 'lastName') {
      dispatch(setSignUpLastName(value));
    } else if (name === 'phone') {
      dispatch(setSignUpPhone(value));
    } else if (name === 'country') {
      dispatch(setSignUpCountry(value));
    }
  };

  const [phone, setPhone] = useState(''); // State for phone number

  return (
    <div className="flex flex-col justify-center w-1/2 pb-1 pt-8 px-16 gap-4">
      <InputField label="Nom" placeholder="Votre nom" handleChange={handleChange} name={'firstName'}/>
      <InputField label="Prenom" placeholder="Votre prénom" handleChange={handleChange} name={'lastName'} />
      <InputField label="Email" type="email" placeholder="Votre email" handleChange={handleChange} name={'email'} />
      <CountrySelect name={'country'} handleChange={handleChange}/>

      <PhoneInput
        country={'gn'}
        value={phone}
        specialLabel=''
        onChange={(phone) => handleChange("phone", phone)}
        disableDropdown={false}
        inputStyle={{  height: '60px', width: '100%', borderRadius: '5px', border: 'none', backgroundColor: '#f3f4f6' }}  
        placeholder=""
      />

      <InputField label="Mot de passe" type="password" placeholder="Votre mot de passe" showPasswordToggle showicon={false} handleChange={handleChange} name={'password'} />
      <InputField label="Confirmez le mot de pass" type="password" placeholder="Confirmez votre mot de passe" showPasswordToggle showicon={false} handleChange={handleChange} name={'confirm_password'} />

      <button onClick={handleContinue} className="self-end  bg-buttongreen text-white px-8 py-4 rounded-md  w-[60%] bg-buttongreen text-2xl rounded-xl font-semibold">suivant</button>
    </div>
  );
};
export default SignUpForm;
