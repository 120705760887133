// src/api/authApi.js
import axios from "axios";
import { store } from "../store";
import { logoutSuccess, refreshToken } from "../store/authSlice";

// Create an axios instance
const apiClient = axios.create({
  baseURL: process.env.REACT_APP_APIURL,
});

// Attach token to every request
apiClient.interceptors.request.use(
  (config) => {
    const state = store.getState();
    const accessToken = state.auth.accessToken;

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Refresh token if access token expires
apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const state = store.getState();
        const refresh_token = state.auth.refreshToken;

        const response = await axios.post(
          `${process.env.REACT_APP_APIURL}/auth/jwt/refresh`,
          { refresh: refresh_token }
        );
        if (response.status === 200) {
          store.dispatch(refreshToken({ accessToken: response.data.access }));
          originalRequest.headers[
            "Authorization"
          ] = `Bearer ${response.data.accessToken}`;
          return apiClient(originalRequest);
        } else {
          store.dispatch(logoutSuccess());
        }
      } catch (refreshError) {
        store.dispatch(logoutSuccess());
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
