import React from 'react';

const SubscriptionTable = () => {
  const rowData = [
    { name: "Anouna", plan: "Premium", amount: "$10", startDate: "05-02-2024", endDate: "05-03-2024", status: "Going On", action:'Renew' },
    { name: "Anouna", plan: "Premium", amount: "$10", startDate: "05-02-2024", endDate: "05-03-2024", status: "Done" , action:'Finished'},
    { name: "Anouna", plan: "Premium", amount: "$10", startDate: "05-02-2024", endDate: "05-03-2024", status: "Done", action:'Finished' },
    // Add more rows as needed
  ];
  
  return (

    <div className="w-full bg-white rounded-lg shadow py-6 px-2 mt-4 overflow-x-auto">
      <h3 className="pl-4 text-lg font-semibold">Subscription</h3>
      <table className="w-full mt-4 border-collapse">
        <thead >
          <tr className="bg-tcfbackground border rounded-[15px] text-18px ">
            {["Membership", "Plan Details", "Amount", "Start On", "Expires On", "Status", "Action"].map((heading, index) => (
              <th
                key={index}
                className=" py-3  text-center leading-[30.86px] whitespace-nowrap font-medium text-gray-700"
              >
                {heading}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {rowData.map((row, rowIndex) => (
    <tr key={rowIndex} className=" border-b-[2px]  border-tcfbackground whitespace-nowrap">
      <td className=" font-bold px-5 py-2">{row.name}</td>
      <td className=" pl-5 py-2">{row.plan}</td>
      <td className=" pl-5 py-2">{row.amount}</td>
      <td className="pl-5 py-2">{row.startDate}</td>
      <td className=" pl-5 py-2">{row.endDate}</td>
      <td className={`pl-6 py-2 ${row.status === "Going On" ? "text-tcfgreen" : ""}`}>{row.status}</td>
      <td className="px-2 py-2">
        <button className={` w-full text-white px-4 py-1 rounded hover:bg-green-600 transition duration-150 ${row.status === "Going On" ? "bg-green-500" : "bg-tcfred"}`}>
         {row.action}
        </button>
      </td>
    </tr>
  ))}
          
        </tbody>
      </table>
    </div>

  );
};

export default SubscriptionTable;
