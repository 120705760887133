import React from 'react';

const PointsProgress = () => (
 

  <div className="h-full bg-white px-3 rounded-lg shadow text-center flex flex-col justify-around">
  <div>
    <h3 className="text-sm font-normal text-gray-400">Vous avez atteint</h3>
    <p className="text-5xl font-extrabold">099<span className='align-super text-sm font-normal'>Pts</span></p>
  </div>
  <button className="w-full py-2 bg-green-500 text-white rounded">Actualiser</button>
</div>

);

export default PointsProgress;
