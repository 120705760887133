// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;


// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from 'react-router-dom';
import HomePage from './pages/HomePage';
import DashboardPage from './pages/DashboardPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignUpPage';
import PaymentPage from './pages/PaymentPage';
import PaymentConfirmationPage from './pages/PaymentConfirmationPage';
import { useSelector } from 'react-redux';

// AuthenticatedRoute component
const AuthenticatedRoute = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

// App component
function App() {
  return (
    <Router>
      <div className="">
        <Routes>
          {/* Redirect root URL to login */}
          <Route path="/" element={<Navigate to="/homepage" />} />
          {/* Landing page */}
          <Route path="/homepage" element={<HomePage />} />

          {/* Protected dashboard route */}
          <Route element={<AuthenticatedRoute />}>
            <Route path="/dashboard" element={<DashboardPage />} />
          </Route>

          {/* Other routes */}
          {/* Additional routes can go here for other sections if needed */}
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/payment" element={<PaymentPage />} />
          <Route
            path="/payment/confirmation"
            element={<PaymentConfirmationPage />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
