// components/QuestionCard.js
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setComprehensionWrittenResponse } from '../store/questionsSlice';

function QuestionCard({ question }) {
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const dispatch = useDispatch();
  const handleSelect = (response) => {
    setSelectedAnswer(response);
    dispatch(setComprehensionWrittenResponse({ questionId: question.id, response }));
  };
  
  return (
    <div className="grid grid-cols-2 gap-5 mt-4">
      {question?.options?.map((option, i) => (
        <button
          key={i}
          onClick={() => handleSelect(option.value)}
          className={`text-center flex items-center justify-start px-3 py-5 border rounded-md ${
            selectedAnswer === question.id ? "bg-tcfbackground" : ""
          }`}
        >
          <input
            type="checkbox"
            name={`answer-${question.text}`} 
            value={option.value}
            checked={question?.response === option.value}
            className="w-4 h-4 mr-4"
            onChange={() => handleSelect(option.value)}
          />
          {option.text}
        </button>
      ))}
    </div>
  );
}

export default QuestionCard;