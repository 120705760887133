// LoginForm.js
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import InputField from './InputField';
import userIcon from '../images/user.svg'
import lock from '../images/lock.svg'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '../store/authSlice';
import axios from 'axios';


const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const handleSubmit = async () => {
        const apiURL = process.env.REACT_APP_APIURL || '';
        try {
            const response = await axios.post(`${apiURL}/auth/login/jwt/create/`, { email, password });
            if (response.status === 200) {
                dispatch(loginSuccess({
                    user: response.data.user,
                    access: response.data.access,
                    refresh: response.data.refresh,
                }));
                navigate('/dashboard');
            } else {
                setError('Something went wrong. Please try again.');
            }
        } catch (error) {
            setError(error.response.data.detail);
        }
    };

    const handleChange = (name, value) => {
        if (name === 'email') {
            setEmail(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };
    

   

    return (
        <div className="flex flex-col justify-center w-1/2 px-16 gap-4 ">
            <h3 className="text-2xl font-bold text-center ">Connectez-vous</h3>
            <p className="text-center text-gray-500 mb-6">Connectez-vous avec vos identifiants</p>

           
            <div className="flex flex-col justify-center w-full py-4  gap-4">
            <InputField  icon={userIcon} placeholder="Entrez votre email" showicon={true} name={'email'}  handleChange={handleChange}/>
            <InputField type="password" icon={lock} placeholder="Mot de passe" showicon={true} name={'password'} showPasswordToggle handleChange={handleChange}/>
            </div>


            <p className='text-md text-gray-400 mb-4 '>Mot de passe oublié?
                <a href="#" className="text-tcfdeepgreen"> Cliquez ici</a>
            </p>


            <button onClick={handleSubmit} className="w-full bg-black text-2xl text-white py-4 rounded-xl font-semibold  mb-[160px]">Se Connecter</button>

            <p className="text-center text-lg">
                Vous n’avez pas encore de compte?  <NavLink className="text-tcfdeepgreen" to="/signup">S’inscrire</NavLink>
            </p>
        </div>
    );
};

export default LoginForm;
