// src/app/store.js
import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import authReducer from "./authSlice";
import questionsReducer from "./questionsSlice";

const store = configureStore({
  reducer: {
    auth: authReducer,
    questions: questionsReducer,
  },
});

const persistor = persistStore(store);

export { store, persistor };
