// src/components/DropdownSelect.js
import React from 'react';

const DropdownSelect = ({ label, options, handleChange, ...props }) => (
  <div className="mb-4">
    <label className="text-gray-200 block text-sm font-medium mb-1">{label}</label>
    <select className="w-full px-2 rounded-md bg-gray-100 border-none py-4 text-gray-400" {...props}>
      <option onClick={() => handleChange('')} value="">Veuillez sélectionner</option>
      {options.map((option, index) => (
        <option key={index} value={option.value} onClick={() =>handleChange(option.value)}>{option.label}</option>
      ))}
    </select>
  </div>
);

export default DropdownSelect;
