// src/pages/PaymentPage.js
import React, { useEffect, useState } from 'react';
import DropdownSelect from '../components/DropdownSelect';
import PaymentCardInput from '../components/PaymentCardInput';
import Checkbox from '../components/Checkbox';
import illustration from '../images/illustration.jpg'
import InputField from '../components/InputField';
import apiClient from '../services/httpService';
import { useSelector } from 'react-redux';
import PaystackPayment from './PaystackPayment';
import { useNavigate } from 'react-router-dom';

const PaymentPage = () => {
    const [planOptions, setPlanOptions] = useState([]);
    const [price, setPrice] = useState(0);
    const [selectedPlanId, setSelectedPlanId] = useState(0);
    const [paymentOption, setPaymentOption] = useState('');
    const { signUp, user, isAuthenticated } = useSelector((state) => state.auth);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const paymentOptions = [
        { label: 'Carte Bancaire', value: 'card' },
        { label: 'Paypal', value: 'paypal' }
    ];

    const handlePlanChange = (value) => {
        if (value) {
            const selectedPlan = planOptions.find(plan => plan.value === value);
            setPrice(selectedPlan.price);
            setSelectedPlanId(value);
        }else{
            setPrice(0);
            setSelectedPlanId(0);
        }
    };

    const handlePaymentOptionChange = (value) => {
        setPaymentOption(value);
    };

    const handleSubmit = () => {
        if (!isAuthenticated) {
            if (!signUp.firstName) {
                setError('First name is required');
            }
            if (!signUp.lastName) {
                setError('Last name is required');
            }
            if (!signUp.email) {
                setError('Email is required');
            }
            if (!signUp.password) {
                setError('Password is required');
            }
            if (!signUp.country) {
                setError('Address is required');
            }
            if (!signUp.country) {
                setError('Country is required');
            }
            if (!paymentOption) {
                setError('Payment option is required');
            }
        }
    }

    const fetchSubscriptions = async () => {
        const response = await apiClient.get('/mgmt/available-subscriptions/');
        if (response.status === 200) {
            let options = [];
            response.data?.forEach(subscription => {
                const data = {
                    label: subscription.plan_name,
                    value: subscription.id,
                    price: subscription.amount
                }
                options.push(data);
            });
            setPlanOptions(options);
        } else {
            console.log('error');
        }
    }

    useEffect(() => {
        if (!isAuthenticated && !signUp?.email) {
            navigate('/signup');
        }
        fetchSubscriptions();
    }, [isAuthenticated, signUp?.email, navigate]);
    console.log(paymentOption);
    
    return (
        <div className="flex w-full flex-col items-center bg-tcfbackground  h-screen py-10 px-[10px] xl:px-[67px] justify-center ">
            <div className="flex h-full bg-white rounded-[20px] shadow-lg overflow-hidden p-8 justify-between">

                {/* Left Panel */}
                <div className=" flex flex-col w-3/5 bg-black px-6 text-white rounded-[20px] justify-around ">
                    <h2 className="text-center text-3xl font-bold mb-4">Votre commande</h2>
                    <div className='flex flex-col gap-8'>
                        <div className='flex flex-col justify-between bg-tcfgray rounded-[20px] p-4 '>
                            <p className="text-lg font-semibold ">Choisir votre forfait</p>
                            <DropdownSelect  options={planOptions} handleChange={handlePlanChange} />
                            <p className="flex text-lg font-semibold justify-between ">Prix:<span className='text-xl font-bold'>${price}</span></p>

                        </div>
                        <div className='bg-tcfgray rounded-[20px] p-4'>
                            <h3 className="text-3xl font-bold mb-2">Mode de Paiement</h3>
                            <DropdownSelect  options={paymentOptions} handleChange={handlePaymentOptionChange} />
                            {/* <InputField placeholder="Numéro carte de crédit" />

                            <PaymentCardInput /> */}

                        </div>
                    </div>
                    <div className=''>
                        <Checkbox cstyle='w-4 h-4 mr-2' lstyle='text-sm' label="Enregistrer les informations de paiement sur mon compte pour de futurs achats." />
                    </div>

                </div>

                {/* Right Panel */}
                <div className="flex flex-col w-1/2 pl-10 justify-between">
                    <img src={illustration} alt='' className='' />
                    <p className="text-lg mb-4">
                        Vos données personnelles seront utilisées pour le traitement de votre commande, vous accompagner au cours de votre visite du site web, et pour d’autres raisons décrites dans notre politique de confidentialité.
                    </p>


                    <Checkbox cstyle='w-4 h-4 mr-2' label="J'ai lu et j’accepte les " lstyle='text-xl' text='conditions générales' style='text-tcfred'/>
                    {(paymentOption === "" || price === 0) && <button disabled type='submit' className="w-full bg-red-500 text-white py-3 rounded-md font-semibold mt-6">
                        Sélectionnez le mode de paiement
                    </button>}
                    {paymentOption === "card" && price > 0 && <form onSubmit={handleSubmit} method="POST" action={`${process.env.REACT_APP_APIURL}/payment/stripe/create-checkout-session/`}>
                        <input type="hidden" name="amount" value={price || 0} />
                        <input type="hidden" name="sub_id" value={selectedPlanId || ''} />
                        {isAuthenticated && <input type="hidden" name="email" value={user?.email || ''} />}
                        {!isAuthenticated && <input type="hidden" name="email" value={signUp.email || ''} />}
                        {!isAuthenticated && <input type="hidden" name="password" value={signUp.password || ''} />}
                        {!isAuthenticated && <input type="hidden" name="address" value={signUp.country || ''} />}
                        {!isAuthenticated && <input type="hidden" name="first_name" value={signUp.firstName || ''} />}
                        {!isAuthenticated && <input type="hidden" name="last_name" value={signUp.lastName || ''} />}
                        {!isAuthenticated && <input type="hidden" name="country" value={signUp.country || ''} />}
                        {/* <button className="button-bg text-white py-2 rounded-lg px-4 animate-pulse">Register</button> */}
                        <button type='submit' className="w-full bg-buttongreen text-2xl text-white py-4 rounded-xl font-semibold mt-6">
                            S&apos;inscription
                        </button>
                    </form>}
                    {paymentOption === "paypal" && price > 0 && <PaystackPayment email={isAuthenticated ? user?.email : signUp.email} amount={price} sub_id={selectedPlanId} password={signUp.password || ''} address={signUp.country || ''} first_name={signUp.firstName || ''} last_name={signUp.lastName || ''} country={signUp.country || ''}/>}
                </div>
            </div>
        </div>
    );
};

export default PaymentPage;
