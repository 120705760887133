// SeriesButtons.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentIndex } from '../store/questionsSlice';

const TaskNumbers = ({questions}) => {
  const { currentIndex } = useSelector((state) => state.questions);
  const dispatch = useDispatch();
  return (
    <div className="grid grid-cols-12 gap-y-2 bg-white p-4 rounded-[15px] justify-items-center ">
      {questions?.map((_, i) => (
        <button
          key={i}
          onClick={() => dispatch(setCurrentIndex(i))}
          className={`w-10 h-10 bg-white text-sm font-bold
         text-gray-800 hover:bg-tcfbackground rounded-full flex items-center justify-center leading-none ${i === currentIndex ? "bg-blue-300" : "bg-gray-300"
            } ${questions[i].revise_question && !questions[i].response ? "bg-yellow-500" : ""} ${questions[i].response && "bg-green-500"} ${i === currentIndex && questions[i].revise_question ? "text-white" : ""}`}
        >
          {i + 1}
        </button>
      ))}
    </div>


  )
};

export default TaskNumbers;
