import React from 'react';
import statsIcon from '../images/stats.svg'


const Statistics = () => (
  <div className="flex flex-col gap-6 bg-white w-full rounded-lg shadow px-3 pt-3">
    <div className='flex gap-2  items-center'>
      <img className="w-5 h-5 bg-tcfbackground rounded-[5px]" src={statsIcon} alt="" />

      <h3 className="text-lg font-semibold">Statistiques</h3>
    </div>


    <div className="pl-4">
      {[
        { name: "Expression Écrite", progress: 70, color: "bg-tcfyellow" },
        { name: "Expression Orale", progress: 50, color: "bg-tcfpink" },
        { name: "Compréhension Orale", progress: 60, color: "bg-tcfblue" },
        { name: "Compréhension Écrite", progress: 80, color: "bg-tcfpurple" }
      ].map((stat, index) => (
        <div key={index} className="mb-3">
          <div className="flex text-sm justify-between ">
            <span>{stat.name}</span>
            <div className="w-[70%] bg-gray-200 h-2 rounded-full mt-1">
              <div
                className={`${stat.color} h-full rounded-full`}
                style={{ width: `${stat.progress}%` }}
              ></div>
            </div>
          </div>

        </div>
      ))}
    </div>
  </div>


);

export default Statistics;
