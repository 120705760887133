import React from 'react';
import bannerimage from "../images/bannerimage.jpg"
import tcf from "../images/TCF.png"
import homepagestudents from "../images/homepagestudents.png"
import learn from "../images/learn.png"
import settings from "../images/settings.png"
import timer from "../images/timer.png"
import announcement from "../images/announcement.png"
import CheckmarkIcon from '../images/checkmark.svg'
import logo from '../images/TCFlogo.png'
import assist from '../images/assist.png'
import InputField from '../components/InputField';
import background from '../images/backgroundhorizontal.png'
import profileimage from '../images/profileimage.jpg'
import HomeNavigation from '../components/Navbar';
import Footer from '../components/Footer'



const Home = () => {
  const cardData = [
    {
      plan: "ATHRY",
      duration: "30 Days",
      price: "USD 1",
      features: ["Nous offrons un suivi particulier", "Nous offrons un suivi particulier", "Nous offrons un suivi particulier",],
    },
    {
      plan: "PLATINUM",
      duration: "30 Days",
      price: "USD 28",
      features: ["Nous offrons un suivi particulier", "Nous offrons un suivi particulier", "Nous offrons un suivi particulier",],
    },
    {
      plan: "ATHRY",
      duration: "30 Days",
      price: "USD 1",
      features: ["Nous offrons un suivi particulier", "Nous offrons un suivi particulier", "Nous offrons un suivi particulier",],
    },
  ];

  function Card({ plan, duration, price, features, isGreen }) {
    return (
      <div className={`px-[30px] py-[47px]  text-[#12372A] shadow-lg rounded-[20px] flex flex-col items-center ${isGreen ? "bg-[#9BCF53]" : "bg-white border border-[#12372A]"}`}>
        <h3 className="text-3xl font-normal">{plan}</h3>
        <p className="text-5xl font-bold mt-2">{price}</p>
        <p className="text-lg text-gray-500">{duration}</p>
        <ul className="mt-4 space-y-6">
          {features.map((feature, index) => (
            <li key={index} className="flex items-center gap-[6px] text-gray-700 text-sm">
              <div className="flex items-center justify-center border w-[15px] h-[15px] border-[#12372A] rounded-full">
                <img src={CheckmarkIcon} alt="checkmark icon" className="w-[10px] h-[10px]" />
              </div> {feature}
            </li>
          ))}
        </ul>
        <button className="text-white font-semibold text-lg px-[74px] py-[11px] rounded-md mt-[80px] " style={{
          background: 'linear-gradient(to right, rgba(3, 139, 20, 1), rgba(43, 255, 0, 1) 100%)',
        }}>Nos Formations</button>
      </div>
    );
  }




  const clientData = [
    {
      image: profileimage,
      username: "Abraham Lao ",
      handle: "@diallomaria",
      testimonial: "J’ai fini mon teste TCF en 15 jours et j‘avoue c’etait dure mais satisfaisant"
    },
    {
      image: profileimage,
      username: "Mariam Diallo",
      handle: "@diallomaria",
      testimonial: "J’ai fini mon teste TCF en 15 jours et j‘avoue c’etait dure mais satisfaisant"
    },
    {
      image: profileimage,
      username: "Erica Zambo",
      handle: "@diallomaria",
      testimonial: "J’ai fini mon teste TCF en 15 jours et j‘avoue c’etait dure mais satisfaisant",
    },
  ];




  function ClientCard({ image, username, handle, testimonial }) {
    return (
      <div className='px-[32px] pt-[24px] pb-[42px] gap-[41px]  text-[#12372A] bg-[#EDF4F8] shadow-lg rounded-[20px] flex flex-col text-start '>
        <div className='flex gap-[9px] items-center '>
          <img src={image} alt="User" className="w-[60px] h-[60px] rounded-full object-cover" />
          <div>
            <p className="text-start text-lg font-bold mt-2">{username}</p>
            <p className="text-start text-base text-gray-500">{handle}</p>
          </div>
        </div>
        <p className="text-lg w-[286px] text-gray-500">{testimonial}</p>
      </div>
    );
  }

  return (
    <div className='flex flex-col overflow-x-hidden'>
      {/* Navbar at the top */}



      {/* Content Sections */}
      <div className=" h-min-screen "> {/* Padding top to avoid overlap with fixed navbar */}
        {/* Section 1 */}
        <section className=" w-full h-screen relative overflow-hidden   ">


          <img src={bannerimage} alt="Header" className="w-full h-full object-cover transform scale-x-[-1]" />
          <HomeNavigation />
          <div className=" absolute inset-0 right-0 flex   px-[98px]">
            <div className="absolute inset-0 " style={{
              backgroundImage: `
linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 0) 53%),
linear-gradient(to bottom, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0) 18%)
`,
            }}></div>

            <div className='flex flex-col gap-[142px] z-10'>
              <div className='w-[399px] flex flex-col items-start pt-[224px] gap-[7px] relative  '>
                {/* Text content - placed above the overlay */}
                <h2 className="text-7xl font-semibold  text-[#3AA91B]">Se preparer au tcf Canada</h2>
                <p className="text-lg  text-[#12372A] font-semibold">Plateforme dediée à la préparation au tcf canada suivant les conditions réelles de l’examen</p>
                <button className="bg-[#2CFF00] text-[#12372A] font-semibold text-lg px-[40px] py-[11px] rounded-md mt-[23px] ">Get started</button>
              </div>
              <div className='text-[#416D19] flex flex-row gap-[55px] '>
                <div className='flex flex-col items-center'>
                  <h2 className=" text-7xl font-semibold">210</h2>
                  <h2 className="">Incrits</h2>
                </div>
                <div className=' flex flex-col items-center'>
                  <h2 className=" text-7xl font-semibold">150</h2>
                  <h2 className="">Questions</h2>
                </div>
                <div className=' flex flex-col items-center'>
                  <h2 className="text-7xl font-semibold">45</h2>
                  <h2 className="">Test</h2>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* Section 2 */}
        <section className=" px-[67px] xl:px-[102px] flex flex-col pt-[95px] pb-[131px] text-center gap-[77px]">
          <h2 className="text-7xl px-[350px] font-semibold  text-[#3AA91B]">Démarrez votre aventure
            canadienne avec assurance</h2>
          <div className=' flex justify-around'>
            <div className='" flex flex-col  items-center '>
              <img src={learn} alt="learn" className="w-[184px] h-[189px] mb-[16px]" />

              <h2 className="w-[197px] text-3xl font-semibold  text-[#416D19]">Apprentissage
                avancé</h2>
              <p className="w-[258px] text-lg font-semibold  text-[#12372A] ">Préparez-vous avec des tests
                précis
                et des simulations réelles
                d’examen.
              </p>
            </div>
            <div className='" flex flex-col  items-center '>
              <img src={settings} alt="learn" className="w-[177px] h-[189px] mb-[16px]" />

              <h2 className="w-[197px] text-3xl font-semibold  text-[#416D19]">Cours
                Personnalisés</h2>
              <p className="w-[258px] text-lg font-semibold  text-[#12372A] ">Préparez-vous avec des tests
                précis
                et des simulations réelles
                d’examen.
              </p>
            </div>
            <div className='" flex flex-col  items-center '>
              <img src={announcement} alt="learn" className="w-[184px] h-[189px] mb-[16px]" />

              <h2 className="w-[197px] text-3xl font-semibold  text-[#416D19]">Expertise
                Prouveé</h2>
              <p className="w-[258px] text-lg font-semibold  text-[#12372A] ">Préparez-vous avec des tests
                précis
                et des simulations réelles
                d’examen.
              </p>
            </div>
            <div className='" flex flex-col  items-center '>
              <img src={timer} alt="learn" className="w-[184px] h-[189px] mb-[16px]" />

              <h2 className="w-[197px] text-3xl font-semibold  text-[#416D19]">Investissement
                Intelligent</h2>
              <p className="w-[258px] text-lg font-semibold  text-[#12372A] ">Préparez-vous avec des tests
                précis
                et des simulations réelles
                d’examen.
              </p>
            </div>
          </div>


        </section>

        {/* Section 3 */}
        <section className="px-[102px] flex flex-col pt-[70px] pb-[131px] text-center gap-[77px]">
          <h2 className="text-7xl px-[350px] font-semibold  text-[#3AA91B]">Optimisez Votre Préparation au TCF
            avec Notre Expertise</h2>
          <div className='flex justify-around'>
            <img src={homepagestudents} alt="Header" className=" h-[570px] object-cover transform " />

            <div className='flex flex-col gap-[11px] text-start w-[623px]'>
              <h2 className="text-3xl font-semibold  text-[#3AA91B]">Avantages Personnalisés</h2>
              <p className="text-lg font-semibold  text-[#12372A] mb-[120px]">Chaque candidat est unique, et nous le savons.
                Profitez d'un accompagnement
                sur-mesure qui adapte
                nos méthodes pédagogiques à votre parcours, assurant
                un suivi détaillé de vos progrès dans chaque module.
                Votre réussite est
                notre priorité.
              </p>
              <h2 className="text-3xl font-semibold  text-[#3AA91B]">Votre Salle de Classe Virtuelle</h2>
              <p className="text-lg font-semibold  text-[#12372A]">Étudiez où que vous soyez, quand vous le souhaitez. Notre plateforme d'apprentissage
                en ligne vous connecte aux outils les plus avancés et aux méthodes interactives.
                Flexibilité et accessibilité, sans compromis sur la qualité, dès l'instant où vous rejoignez
                notre communauté
                d'apprenants.
              </p>
              <button className="text-white font-semibold text-lg px-[74px] py-[11px] rounded-md mt-[80px] " style={{
                background: 'linear-gradient(to right, rgba(3, 139, 20, 1), rgba(43, 255, 0, 1) 100%)',
              }}>Nos Formations</button>

            </div>
          </div>


        </section>

        {/* Section 4 */}
        <section className=" px-[67px] xl:px-[102px] flex flex-col pt-[95px] pb-[131px] text-center gap-[77px]">
          <div>
            <h2 className="text-7xl px-[350px] font-semibold  text-[#3AA91B] mb-[12px]">Nos Forfait Pourformation
              Chez Nous</h2>
            <p className="text-lg font-semibold  text-[#12372A] ">Faites vos choix, enregistrez-vous, et commencez aujourd'hui</p>
          </div>
          <div className="flex flex-wrap gap-4  p-6 justify-center">
            {cardData.map((card, index) => (
              <Card
                key={index}
                plan={card.plan}
                duration={card.duration}
                price={card.price}
                features={card.features}
                isGreen={index === 1}
              />
            ))}
          </div>


        </section>

        {/* Section 5 */}
        <section className=" w-full  relative overflow-hidden   ">

          <img src={tcf} alt="tcf" className="w-full h-[500px]  object-cover " />
          <div className=" absolute inset-0 right-0 flex  px-[98px]">
            <div className='flex flex-col justify-center gap-[31px] z-10'>
              <div className='w-[399px] flex flex-col items-start pt-[24px] gap-[7px] relative  '>
                {/* Text content - placed above the overlay */}
                <img src={logo} alt='' />
                <p className="text-lg  text-white font-normal">Nous vous aidons à certifier
                  votre niveau de français afin
                  de faciliter votre immigration
                  au Canada
                </p>
              </div>

            </div>
          </div>

        </section>

        {/* Section 6 */}
        <section className="px-[102px] flex flex-col pb-[65px] text-center ">
          <div className='flex justify-around items-center'>
            <img src={assist} alt="Header" className=" h-[570px] object-cover transform " />

            <div className='flex flex-col gap-[11px] text-start '>
              <h2 className="text-3xl font-semibold  text-[#3AA91B]">À Votre Service, Où Que Vous Soyez!</h2>
              <p className="w-[455px] text-lg font-semibold  text-[#12372A]">Étudiez où que vous soyez, quand vous le souhaitez. Notre plateforme d'apprentissage
                en ligne vous connecte aux outils les plus avancés et aux méthodes interactives.
                Flexibilité et accessibilité, sans compromis sur la qualité, dès l'instant où vous rejoignez
                notre communauté
                d'apprenants.
              </p>
              <button className="text-white font-semibold text-lg px-[74px] py-[11px] rounded-md mt-[25px] " style={{
                background: 'linear-gradient(to right, rgba(3, 139, 20, 1), rgba(43, 255, 0, 1) 100%)',
              }}>Nos Formations</button>

            </div>
          </div>


        </section>


        {/* Section 7 */}
        <section className=" w-full  relative   overflow-hidden   ">
          <img src={background} alt="Header" className="w-full h-[720px]  object-cover transform " />
          <div className=" absolute inset-0 ">
            <h2 className="text-7xl py-[44px] px-[350px] text-center font-semibold  text-white mb-[12px]">Cantactez-nous</h2>
            <div className='flex px-[260px] pt-[36px] gap-[76px] '>
              <div className='w-[452px] flex flex-col '>
                <InputField label="Nom" placeholder="Prenom & Nom" name={'firstName'} />
                <InputField label="Nom" placeholder="Exemple@mail.com" name={'firstName'} />
                <textarea
                  placeholder="Ecrire un message"
                  className="w-full p-4 border rounded-[15px] resize-none h-[250px] pr-10"
                  rows="4"
                >
                </textarea>
                <button className="text-[#12372A] bg-[#00FF8B] font-semibold text-lg  py-[11px] rounded-md mt-[25px] ">Envoyez un message</button>
              </div>
              <div className='pt-[4px]'>
                <p className="text-3xl  text-white mb-[13px] font-semibold ">Telephone ou WhatsApp
                </p>
                <p className="text-lg  text-black font-semibold ">Vous pouvez appeler ou nous envoyer des sms au:
                </p>
                <p className='text-white text-lg font-bold mb-[16px] '>1-514-922-5004
                </p>
                <p className="text-lg  text-black font-semibold ">Ou nous ecrire sur WhatsApp
                </p>
                <p className='text-white text-lg font-bold  mb-[16px] '>1-514-922-5004
                </p>
                <p className="text-lg  text-black font-semibold ">Email
                </p>
                <p className='text-white text-lg font-bold '>Contact-Prepmontcfa.com
                </p>
              </div>
            </div>
          </div>
        </section>



        {/* Section 8 */}
        <section className="hidden h-screen bg-gray-800 flex items-center justify-center">
          <h1 className="text-4xl font-bold text-white">Section 8</h1>
        </section>

        {/* Section 9 */}
        <section className=" px-[67px] xl:px-[102px] flex flex-col pt-[95px] pb-[131px] text-center gap-[69px]">
          <div>
            <h2 className="text-7xl px-[308px] font-semibold  text-[#3AA91B] mb-[12px]">Quelque Avis de nos clients satisfaits
              du service</h2>
          </div>
          <div className="flex flex-wrap px-[80px] xl:px-[126px] gap-[24px] xl:gap-[47px] justify-center">
            {clientData.map((clientcard, index) => (
              <ClientCard
                key={index}
                image={clientcard.image}
                username={clientcard.username}
                handle={clientcard.handle}
                testimonial={clientcard.testimonial}
              />
            ))}
          </div>
        </section>

      </div>

      <Footer />

    </div>
  );
};

export default Home;
