// Header.js
import React, { useEffect } from 'react';
import notificationbell from "../images/notification-black.svg"
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logoutSuccess } from '../store/authSlice';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutSuccess());
    navigate("/login");
  };
  const { user } = useSelector((state) => state.auth);
  
  return (
    <header className="flex flex-row justify-between items-center  p-4 rounded-r-[15px] bg-white shadow ">
      <div className='flex flex-col'>
        <h1 className="text-xl text-gray-600 font-200 ">Bonjour <span className=' text-black font-extrabold'> {user?.first_name}</span></h1>
        <h1 className="text-xs font-200 text-gray-500 ">Lundi 04 Nov 2024 </h1>
      </div>
      <input
        type="text"
        placeholder="Rechercher quelque chose ..."
        className="border rounded-md px-2 py-2 min-w-[250px]  text-sm placeholder:text-[14px]  bg-tcfbackground "
      />
      <div className='flex flex-row items-center gap-2'>
        <div className="w-[40px] h-[40px] rounded-full bg-tcfbackground flex items-center justify-center">
          <img src={notificationbell} alt='' className='' />
        </div>

        <button onClick={handleLogout} className="bg-[#383838] text-white px-4 py-2 rounded-md">Déconnexion</button>
      </div>
    </header>
  )
};

export default Header;
