// ResponseSection.js
import React from 'react';
import QuestionCard from './QuestionCard';
import { useSelector } from 'react-redux';


const ResponseSection = ({ question }) => {
  const { currentIndex } = useSelector((state) => state.questions);
  return (
    <div className="bg-white py-4 px-4 rounded-[15px] shadow flex flex-col gap-3 ">

      <div className="flex flex-col items-center  bg-gray-100 p-5 rounded-[15px] gap-2">

        <p className="text-xl font-bold  self-start text-white rounded-l-md rounded-br-[14px] rounded-tr-[2px] border-none bg-black px-5 py-2 ">{currentIndex + 1}</p>

        <p className="text-[20px] leading-[30.86px] font-normal text-gray-800 ">
          ATTENTION
        </p>

        <p className="text-[24px] leading-[30.86px] font-extrabold text-gray-800 ">
          {question?.description}
        </p>
        <p className="text-[18px]  mt-1">{question?.text} ?</p>
      </div>
      <div>
        <p className="text-sm text-[#ff0000] mt-1">* Cocher une des renonços cuivantes</p>

      </div>


      <div className=" w-full mb-3 ">
        <QuestionCard question={question}/>
      </div>

    </div>
  )
};

export default ResponseSection;
