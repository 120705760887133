// ProgressBar.js
// import React from 'react';

// const ProgressBar = () => (
//   <div className='flex flex-row items-center space-x-4 items-center'>
//   <div className="text-xs text-gray-500 text-center ">Temps Écoulé 01:46:59</div>
//   <div className="bg-gray-200 h-2 rounded-full overflow-hidden flex-1">
//     <div className="bg-[#026d00] h-full w-1/3"></div>
//   </div>
// </div>

// );

// export default ProgressBar;

import React, { useEffect, useState } from 'react';

const ProgressBar = ({ totalTime =3600}) => {
  const [elapsedTime, setElapsedTime] = useState(0);

  // Calculate progress percentage
  const progressPercentage = (elapsedTime / totalTime) * 100;

  // Convert elapsed time in seconds to HH:MM:SS format
  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    // Update the elapsed time every second
    const interval = setInterval(() => {
      setElapsedTime((prev) => (prev < totalTime ? prev + 1 : prev));
    }, 1000);

    return () => clearInterval(interval); 
  }, [totalTime]);

  return (
    <div className='flex flex-row items-center space-x-4'>
      <div className="text-xs text-gray-500 text-center">Temps Écoulé {formatTime(elapsedTime)}</div>
      <div className="bg-gray-200 h-2 rounded-full overflow-hidden flex-1">
        <div
          className="bg-[#026d00] h-full transition-all duration-500"
          style={{ width: `${progressPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;

