import React from 'react';
import TaskButtons from './TaskButtons';
import TestSection from './ExpressionEcriteTestSection';
import TabView from './TabView';

const ExpressionEcriteSection = () => {
    return (
       
            <div className="flex flex-col gap-4">
                <TabView/>
                <TaskButtons/>
                <TestSection/>
               
            </div>
       
    );
};

export default ExpressionEcriteSection

