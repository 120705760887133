import React from 'react';
import homelogo from '../images/TCFlogo2.png';

const Footer = () => {
  
  return (

    <div className="pt-[64px] px-[67px] xl:px-[102px] pb-[49px] bg-[#12372A] text-white ">
      <a href="/">
        <img src={homelogo} alt="Home Logo" className="h-[90px] w-[198px] " />
      </a>
      <div className='flex pt-[41px] justify-between '>
        <div className='grid grid-cols-3 text-lg justify-items-end' >
          <div>
            <p className='font-bold mb-[17px]'> Nos Contacts </p>
            <p>Phone: 1-514-922-5004</p>
            <p>Whatsapp: 1-514-922-5004</p>
            <p>SMS: 1-514-922-5004</p>
            <p>Email: test@mail.com</p>
          </div>
          <div className='space-y-[6px]'>
            <p className='font-bold mb-[11px]'>Les Pages </p>
            <p>Compréhension Écrite</p>
            <p>Compréhension Orale</p>
            <p>Expression Écrite</p>
            <p>Expression Orale</p>
          </div>
          <div className='space-y-[6px]'>
            <p className='font-bold mb-[11px]'> Les Méthodologies </p>
            <p>Compréhension Écrite</p>
            <p>Compréhension Orale</p>
            <p>Expression Écrite</p>
            <p>Expression Orale</p>
          </div>
        </div>
        <div className=''>
          <p className='text-3xl font-bold mb-[5px]'> Newsletter </p>
          <p>Nos équipes sont à votre écoute 24/7 pour garantir
            une satisfaction sans faille. .</p>
          <div className='flex gap-[8px] mt-[25px]'>
            <input
              type='text'
              placeholder='Exemple@mail.com'
              className=" flex-grow outline-none bg-white h-[45px] rounded-[10px] placeholder:text-tcfgray pl-[28px]"
              />
            <button className="bg-[#2CFF00] text-[#12372A] font-semibold text-lg px-[45px] py-[11px] rounded-[10px] ">Subscribe</button>
          </div>
        </div>
      </div>


      <div className='border-t border-[3px] border-[#9BCF53] mt-[79px]'></div>
      <h1 className=' mt-[26px]  font-normal items-center'>© 2024 TCF Canada. All rights reserved.</h1>

    </div>

  )
}

export default Footer

