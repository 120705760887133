const HalfDonutProgress = ({ percentage = 100 }) => {
    const rotationDegree = (percentage/100)*180; // Calculate rotation based on percentage
  
    return (
      <div className="relative w-32 h-16 overflow-hidden ">
        {/* Background Circle */}
        <div className="w-32 h-32 rounded-full border-[20px] rounded-full border-[#ff5b00]  absolute top-0 left-0"></div> 
        
        {/* Foreground Progress */}
         <div
          className="w-32 h-32 rounded-full border-[20px] border-gray-200  absolute top-0 left-0 overflow-hidden"
          style={{
            clipPath: "inset(0 0 50% 0)",
            transform: `rotate(${rotationDegree}deg)`,
          }}
        ></div>
        
        {/* Center Text */}
        <div className="absolute inset-0 flex justify-center items-center">
          <span className="align-super text-lg font-bold text-gray-700">{percentage}%</span>
         
        </div>
      </div>

//     <div className="relative w-32 h-16 overflow-hidden">
//     {/* Background Circle with 3D shadow */}

//     {/* Foreground Progress with gradient and 3D shadow */}
//     <div        
//       className="w-32 h-32 rounded-full border-[20px] absolute top-0 left-0 overflow-hidden"
//       style={{
//         clipPath: "inset(0 0 0% 0)", // Show only top half
//         transform: `rotate(${rotationDegree}deg)`,
//         borderColor: "orange",
//         // background: "linear-gradient(135deg, #ffa500, #ff7f50)", // Gradient for 3D effect
//         boxShadow: "inset 0px 4px 8px rgba(0, 0, 0, 0.2)", // Inner shadow for depth
//       }}
//     ></div>
//         <div className="w-32 h-32 rounded-full border-[20px] border-gray-200 absolute top-0 left-0 shadow-lg"></div>


//     {/* Center Text */}
//     <div className="absolute inset-0 flex justify-center items-center">
//       <span className="text-lg font-bold text-gray-700">{percentage}%</span>
//     </div>
//   </div>        
    );
  };
  
  export default HalfDonutProgress;
  