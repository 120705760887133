// Banner.js
import React from 'react';
import bannerimage from "../images/bannerimage.jpg"

const Banner = () => (




  <div className="rounded-[15px] h-[200px] relative overflow-hidden">
  <img src={bannerimage} alt="Header" className="w-full h-full object-cover transform scale-x-[-1]" />
  <div className="absolute inset-0 bg-black opacity-30 rounded-[15px]"></div> {/* Dark overlay */}
  <div className="absolute inset-0 flex items-center justify-center text-center text-white px-4">
    <div>
      <h2 className="text-lg font-semibold">PRÉPAREZ VOUS AU TCF</h2>
      <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
    </div>
  </div>
</div>



);

export default Banner;
