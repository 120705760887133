// src/pages/SignUpPage.js
import React from 'react';
import SignupSidePanel from '../components/AcessSidePanel';
import SignUpForm from '../components/SignUpForm';
import tcflogo from '../images/TCFlogo.png';
import logoImage1 from '../images/signupimage1.png';
import logoImage2 from '../images/signupimage2.png';


const SignUpPage = () => (

  <div className="flex h-screen bg-tcfbackground  py-10 px-[10px] xl:px-[67px] items-center justify-center ">
  <div className="flex h-full bg-white rounded-[20px]  overflow-hidden ">
    <SignupSidePanel logo={tcflogo} bannerimage1={logoImage1} bannerimage2={logoImage2}/>
    
    <SignUpForm />
  </div>
</div>
);

export default SignUpPage;
