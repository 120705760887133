// LoginPage.js
import React from 'react';
import LoginSidePanel from '../components/AcessSidePanel';
import LoginForm from '../components/LoginForm';
import tcflogo from '../images/TCFlogo.png';
import logoImage from '../images/loginimage.png';

const LoginPage = () => {
  return (
    <div className="flex h-screen bg-tcfbackground   py-10 px-[10px] xl:px-[67px] items-center justify-center ">
      <div className="flex  h-full bg-white rounded-[20px]  overflow-hidden">
        <LoginSidePanel  logo={tcflogo} bannerimage1={logoImage}/>
        <LoginForm />
      </div>
    </div>
  );
};

export default LoginPage;
