// Sidebar.js
import React  from 'react';
import profileimage from '../images/profileimage.jpg'
import bookicon from '../images/bookicon.svg'
import peopleicon from '../images/peopleicon.svg'
import notesicon from '../images/notesicon.svg'
import messageicon from '../images/messageicon.svg'
import foursqaures from '../images/foursquares.svg'
import euroicon from '../images/euroicon.svg'
import customerstar from '../images/customerstar.svg'
import { useSelector } from 'react-redux';



const Sidebar = ({ setActiveSection}) => {
  // // Create a reference for the default sidebar item
  // const defaultItemRef = useRef(null);

  // // Focus the default item on component load
  // useEffect(() => {
  //   if (defaultItemRef.current) {
  //     defaultItemRef.current.focus();
  //   }
  // }, []);

  const { user } = useSelector((state) => state.auth);

  return(
  <aside className="flex flex-col justify-between w-[30%] bg-white py-4 px-[14px] border-r rounded-l-[15px] whitespace-nowrap ">
    <div className=''>
      <div className="flex flex-row gap-[12px] items-center mb-6">
        <img src={profileimage} alt="User" className="w-[120px] h-[120px] rounded-full object-cover" />
        <div className="flex flex-col mt-3">
          <h2 className="text-xl whitespace-nowrap font-bold">{user?.first_name} {user?.last_name}</h2>
            <p className="text-xs text-gray-500 mb-[5px]">@{user?.first_name}</p>
          <div className="flex flex-row gap-2">
            <img className='w-[16px] h-[16px] self-center' src={customerstar} alt='' />
            <p lassName="text-xs"> Premium</p>
          </div>
          <p className="text-xm text-yellow-500">★★★★★★★</p>

        </div>
      </div>
      <button onClick={() => setActiveSection('')} className=" border-2 border-black py-2 px-1 rounded-md mb-[14px] w-full mt-2 ">Acceder au Profil</button>
      <hr className='border-2 border-t border-tcfbackground'></hr>

      <button  className="flex items-center block text-xl font-bold mt-4 py-2 mb-8 " onClick={() => setActiveSection('profilesection')}><img src={foursqaures} alt='' className="w-[24px] h-[24px] mr-[24px]" />Tableau de Bord</button>


      <nav className=''>
        <button  className="block flex items-center py-[2px] mb-2 focus:bg-tcfbackground focus:outline-none  rounded-[3px] w-full " onClick={() => setActiveSection('expressionecritesection')}><img src={bookicon} alt='' className="w-[48px] h-[48px] " />Expression Ecrite</button>
        <button className="block flex items-center py-[2px] mb-2  focus:bg-tcfbackground rounded-[3px] w-full" onClick={() => setActiveSection('expressionecritesection')}> <img src={peopleicon} alt='' className="w-[48px] h-[48px]" />Expression Orale</button>
        <button className="block flex items-center py-[2px] mb-2  focus:bg-tcfbackground rounded-[3px] w-full" onClick={() => setActiveSection('comprehensionecritesection')}> <img src={notesicon} alt='' className="w-[48px] h-[48px]" />Comprehension Ecrite</button>
        <button className="block flex items-center py-[2px] mb-2  focus:bg-tcfbackground rounded-[3px] w-full" onClick={() => setActiveSection('comprehensionecritesection')}> <img src={messageicon} alt='' className="w-[48px] h-[48px]" />Comprehension Orale</button>
      </nav>
    </div>
    <div className="flex flex-col  bg-[#131313] text-white text-center p-3 mt-6 rounded-[15px]">
      <img src={euroicon} alt='' className="w-[80px] h-[80px] self-center" />
      <p className='text-xs'> Passer à la version</p>
      <p className='text-xs'> premium avec plus de tests</p>
      <p className='bg-tcfgray1 font-bold rounded-[10px] py-2 mt-2'>Premium Version</p>

    </div>
  </aside>
);
};

export default Sidebar;
