import React from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import homelogo from '../images/TCFlogo2.png';



const HomeNavigation = () => {
    const navigate = useNavigate()
    return (
        <nav className=" absolute top-0 left-0 right-0 py-[30px] text-[#12372A] text-lg   flex items-center justify-between px-[33px] md:px-[44px] lg:px-[67px]  xl:px-[102px] z-50 ">
            {/* Logo in Navbar */}
            <div className="flex items-center space-x-4">
                <NavLink to="/" className="" >
                    <img src={homelogo} alt="Home Logo" className="h-[28.61px] w-[146px] lg:h-[32.33px] lg:w-[165px] xl:h-[52px] xl:w-[115px] " />
                </NavLink>
            </div>

            {/* Navbar Links (hidden on mobile) */}
            <div className="hidden md:flex md:space-x-[22px] lg:space-x-[30px] items-center font-[900] ">
                <NavLink to="/">
                Compréhension Écrite
                </NavLink>
                <NavLink to="/">
                Compréhension Orale
                </NavLink>
                <NavLink to="/">
                Expression Écrite
                </NavLink>
                <NavLink to="/">
                Expression Orale
                </NavLink>
                {/* More Dropdown large screens */}
                

                <button onClick={() => { navigate("/login")}} className=' flex   px-[53px] items-center justify-center text-base lg:text-sm bg-[#2CFF00] h-[30px] lg:h-[40px] rounded-[10px] flex-shrink-0 leading-[18.9px]  font-[900]'>Login
                  
                </button>
                <button onClick={() => { navigate("/signup")}} className=' flex   px-[53px] items-center justify-center text-base lg:text-sm border border-[#416D19] h-[30px] lg:h-[40px] rounded-[10px] flex-shrink-0 leading-[18.9px]  font-[900]'>Sign up
                  
                  </button>
  


            </div>

           
            
        </nav>
    );
};

export default HomeNavigation;
